.card {
    background: var(--surface-card);
    border: 1px solid #c0c0c0;
    padding: 0.75rem;
    margin-bottom: 2rem;
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    > *:last-of-type {
      margin-bottom: 0 !important;
    }

    > h5 {
      position: absolute;
      top: -8px;
      left: 5px;
      background: var(--surface-card);
      padding: 0 5px;
      width: max-content;
      font-size: 14px;
      font-weight: normal;
      margin: 0;
    }
}

.field {
    margin-bottom: 0.5rem !important;
}

.field-radiobutton label,
.field-checkbox label,
.field label {
    font-size: 12px;
}

.field > label[htmlFor="confectionSizeMax"] {
    width: 20px;
    margin: 0;
    text-align: center;
}


.p-calendar-w-btn .p-button-icon-only {
    padding: 0 !important;
    border-radius: 0;
}

.p-button {
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.p-button.p-button-icon-only {
    border-radius: 0 !important;
    padding: 0 !important;
    width: 24px !important;
    height: 24px !important;

    .p-button-icon {
        font-size: 12px !important;
    }
}

.p-checkbox,
.p-radiobutton{
    height: auto;
}

.p-checkbox .p-checkbox-box {
    border-radius: 0;
    height: 18px;
    width: 18px;
    background: rgba(0,0,0, 0.1);
}

.p-dropdown,
.p-multiselect {
    border-radius: 0;
    background: rgba(0,0,0, 0.1) !important;
    height: 20px !important;
}

.p-dropdown .p-dropdown-clear-icon {
    width: 12px;
    right: 34px;
}

.p-dropdown-trigger-icon.p-icon {
    position: relative;
    top: 1px;
}

.p-multiselect-label {
  padding: 1px 2px !important;
}
.p-inputtext {
    padding: 2px 4px;
    font-size: 12px;
    background: rgba(0,0,0, 0.1);
    border-radius: 0;
    color: black;
}

textarea {
  padding: 2px 4px;
  font-size: 12px !important;
  background: rgba(0,0,0, 0.1);
  border-radius: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #495057;
}

.p-inputtext:read-only:not(.p-dropdown-label) {
  background: rgba(0,0,0, 0.05);
    border:none;
}

.p-inputtext.p-dropdown-label,
.p-dropdown .p-dropdown-trigger {
    border-radius: 0;
}

.p-radiobutton .p-radiobutton-box {
    height: 18px;
    width: 18px;
    background: rgba(0,0,0, 0.1);
}


.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}

.p-datatable .p-skeleton {
  height: 10px !important;
}

.p-datatable .p-datatable-header {
  padding: 10px 10px;
}


.p-datatable .p-datatable-tbody {
  font-size: 12px;
  line-height: 21px;
  font-weight: normal;
}


.p-datatable .p-datatable-thead > tr.col_220 th {
  width: 220px;
}
.p-datatable .p-datatable-thead > tr.col_140 th {
  width: 140px;
}
.p-datatable .p-datatable-thead > tr.col_110 th {
  width: 110px;
}
.p-datatable .p-datatable-tbody > tr > td {
    padding: 0 12px;
}

.p-datatable .p-datatable-thead > tr > th {
  font-weight: bold;
  font-size: 9px;
  text-transform: uppercase;
  padding: 0 12px;
  background-color: #DCEEB0;
  line-height: 21px;
  border-right: 1px solid #8FCD00;
}

.p-dropdown-panel .p-dropdown-items {
  padding:2px 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 4px 5px;
  font-size: 12px;
}

.p-autocomplete-dropdown {
  display: none;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 8px;
}

a.action-link {
  font-size: 12px !important;
  font-weight: 400;
  
  &:hover {
    text-decoration: underline;
  }
}
