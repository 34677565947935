/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import 'assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import 'assets/layout/styles/prime/primeng/primeng.min.css';
@import 'assets/layout/styles/prime/primeflex/primeflex.scss';
@import 'assets/layout/styles/prime/primeicons.css';

/* Demos */
@import 'assets/demo/styles/flags/flags.css';
@import 'assets/demo/styles/badges.scss';
@import 'assets/demo/styles/code.scss';

body {
    min-width: 320px;
}

.app-calendar .p-contextmenu.p-component {
    left: 15px !important;
    top: 15px !important;
}

.app-calendar .p-contextmenu.p-component {
    left: 15px !important;
    top: 15px !important;
}

.p-tabmenu .p-tabmenu-nav {
    border: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
}

.p-tabmenu-nav {
    flex-wrap: wrap !important;
}

@media (max-width: 991px) {
    .layout-topbar .layout-topbar-menu {
        display: none !important;

        &.layout-topbar-menu-mobile-active {
            display: block !important;
        }
    }
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-top: 0.5rem;
}

.dialog-content .search-bar {
    width: 97%;

    @media (min-width: 768px) {
        width: 70%;
    }
}

/* Globale Einstellungen für Dropdowns */
.p-dropdown-panel {
  z-index: 1000 !important; /* Höhere z-index für Dropdown-Panels */
  overflow: visible !important;
}

.p-dropdown {
  position: relative !important;
}

.p-dropdown-items-wrapper,
.p-dropdown-panel .p-dropdown-items {
  max-height: 250px !important; /* Maximale Höhe anpassen */
}

/* Stellt sicher, dass Tabellenzellen die Dropdown-Anzeige nicht blockieren */
.p-datatable .p-datatable-tbody > tr > td {
  overflow: visible !important;
}

.p-datatable .p-datatable-thead > tr > th {
  overflow: visible !important;
}

/* Allgemeine Einstellung für alle Overlay-Komponenten */
.p-component-overlay, 
.p-connected-overlay {
  z-index: 1050 !important;
}

